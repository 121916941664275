export class QueryBuilder {

    private _where = [];
    private _select = [];
    private _appends = [];
    private _with = [];
    private _withCount = [];
    private _order = [];
    private _page = 0;
    private _per_page = 0;
    private _count = false;
    private _scopes = [];
    private _sum = [];

    constructor() {}

    public where(field: string, comparison: string, value: string | number | string[] | number[]) {
        this._where.push(JSON.stringify([field, comparison, value]));
        return this;
    }

    public appends(variable: string | string[]) {
        // console.log('appends list', variable);
        if (typeof(variable) === 'string')
            this._appends.push(variable);
        else if (Array.isArray(variable))
            for (let i in variable) {
                this._appends.push(variable[i]);
                // console.log('appends', variable[i]);
            }
        return this;
    }

    public with(relation: string | string[]) {
        if (typeof relation === 'string')
            this._with.push(relation);
        else if (relation instanceof Array)
            for (let i in relation) {
                if (typeof relation[i] === 'string')
                    this._with.push(relation[i]);
            }
        return this;
    }

    public sum(sum: string | string[]) {
        if (typeof sum === 'string')
            this._sum.push(sum);
        else if (sum instanceof Array)
            for (let i in sum) {
                if (typeof sum[i] === 'string')
                    this._sum.push(sum[i]);
            }
        return this;
    }

    public get all() {
        const result = {
            where: this._where,
            select: this._select,
            appends: this._appends,
            with: this._with,
            withCount: this._withCount,
            order: this._order,
            sum: this._sum,
            page: this._page,
            perPage: this._per_page,
            count: this._count,
            scopes: this._scopes,
        };

        return JSON.parse(JSON.stringify({result}));
    }

    public withCount(relation: string) {
        this._withCount.push(relation);
        return this;
    }

    public select(field: string | string[]) {
        if (typeof field === 'string')
            this._select.push(field);
        else if (field instanceof Array)
            for (let i in field) {
                this._select.push(field[i]);
            }
        return this;
    }

    public orderBy(field: string, order: string) {
        this._order.push(JSON.stringify([field, order]));
        return this;
    }

    public page(page: number) {
        this._page = page;
        return this;
    }

    public per_page(per_page: number) {
        this._per_page = per_page;
        return this;
    }

    public count() {
        this._count = true;
        return this;
    }

    public scope(scope: string | string[]) {
        if (typeof scope === 'string')
            this._scopes.push(scope);
        else if (scope instanceof Array)
            for (let i in scope) {
                this._scopes.push(scope[i]);
            }
        return this;
    }

    public get request() {
        const request = {};
        if (this._where.length > 0) request['where[]'] = this._where;
        if (this._order.length > 0) request['order[]'] = this._order;
        if (this._select.length > 0) request['select'] = JSON.stringify(this._select);
        if (this._appends.length > 0) request['appends'] = JSON.stringify(this._appends);
        if (this._with.length > 0) request['with'] = JSON.stringify(this._with);
        if (this._withCount.length > 0) request['withCount'] = JSON.stringify(this._withCount);
        if (this._sum.length > 0) request['sum'] = JSON.stringify(this._sum);
        if (this._page > 0) request['page'] = this._page;
        if (this._per_page > 0) request['per_page'] = this._per_page;
        if (this._count) request['count'] = true;
        if (this._scopes.length > 0) request['scope'] = JSON.stringify(this._scopes);
        return request;
    }
}