import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iBarcode, iBarcodeUser, iGpts, iGptsVariant, iTranslation} from "../../models/interfaces";

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode-user.component.html',
  styleUrls: ['./barcode-user.component.scss']
})
export class BarcodeUserComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }

  public status = 1;
  public page = 1;
  public perPage = 10;

  public isMobile:boolean = false;

  public statuses:any[] = [];
  public gptsData:any = {};

  public variants:any = {
    data: []
  };

  public gpts:iBarcodeUser[] = [];
  public currentGpts:iBarcodeUser = null;
  public lastCopied = 0;

  public newGpt: iBarcodeUser = null;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }

    this.route.params.subscribe(p => {
      if(!p['barcode_user_id']){
        return;
      }
      this.query.one('barcode', p['barcode_user_id']).then(res => {
        this.selectGpt(res);
      });
    })

    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = -2;
    }
    else {
      this.loadGpts();
    }

  }

  selectGpt(gpt:iBarcodeUser){
    this.currentGpts = gpt;
    this.newGpt = JSON.parse(JSON.stringify(gpt));
  }

  updateVariant(variant:iBarcodeUser){
    this.query.update('barcodeUser', variant.barcode_user_id, variant).then(res => {
      this.loadGpts();
    });
  }

  createVariant(){
    this.query.create('barcodeUser', {}).then(res => {
      this.loadGpts();
    });
  }

  deleteVariant(variant){
    this.query.del('barcodeUser', variant.gpts_variant_id).then(res => {
      this.loadGpts();
    });
  }

  loadGpts(status = 1, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder().per_page(this.perPage).page(this.page).with(['barcodeRel', 'user']).orderBy('barcode_user_id', 'desc');
    this.query.all('barcodeUser', qb).then(res => {
      this.gptsData = res;
      this.gpts = res.data;
      for(let gpt of this.gpts){
        this.parseBlock(gpt.barcode_rel);
      }
    });
  }

  updateData(){
    this.query.update('barcodeUser', this.currentGpts.barcode_user_id, this.newGpt).then(res => {
      this.currentGpts = res;
    });
  }

  parseBlock(json){
    if(!json?.json_data){
      return;
    }
    const block = JSON.parse(json.json_data);
    json.jsonkeys = Object.keys(block);
    json.json = block;
  }

}
