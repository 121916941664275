import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iFood, iRequest} from "../../models/interfaces";

@Component({
  selector: 'app-foodlist',
  templateUrl: './food-list.component.html',
  styleUrls: ['./food-list.component.scss']
})
export class FoodListComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }


  public foods: iFood[] = [];

  public from:any;
  public to:any;

  public user_id = 0;

  ngOnInit(): void {

    this.route.params.subscribe(p => {
      if(!p['user_id']){
        return;
      }

      this.user_id = p['user_id'];

      this.loadByDate();


    })


  }

  loadByDate(){
    const qb = new QueryBuilder()
        .scope('user,'+this.user_id)
        .appends(['date', 'timezone'])
        .orderBy('food_id', 'desc')
    ;

    if(this.from){
      qb.where('created_at', '>=', new Date(this.from).toISOString())
    }
    if(this.to){
      qb.where('created_at', '<=', new Date(this.to).toISOString())
    }

    this.query.all<iRequest<iFood>>('food', qb).then(res => {
      this.foods = res.data;
    });
  }


}
