import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginRegisterComponent} from "./login-register/login-register.component";
import {GptsComponent} from "./gpts/gpts.component";
import {UserComponent} from "./user/user.component";
import {SettingsComponent} from "./settings/settings.component";
import {TranslationsComponent} from "./translations/translations.component";
// import {FoodListComponent} from "./food-list/food-list.component";
import {FoodReportPageComponent} from "./food-report-page/food-report-page.component";
import {BarcodeComponent} from "./barcode/barcode.component";
import {BarcodeUserComponent} from "./barcode-user/barcode-user.component";

const routes: Routes = [

  {path: 'login', component: LoginRegisterComponent},
  {path: 'users', component: UserComponent},
  {path: 'users/:user_id', component: UserComponent},
  {path: 'barcodes', component: BarcodeComponent},
  {path: 'barcodes/:barcode_id', component: BarcodeComponent},
  {path: 'barcodes-users', component: BarcodeUserComponent},
  {path: 'barcodes-users/:barcode_user_id', component: BarcodeUserComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'settings/:setting_id', component: SettingsComponent},
  {path: 'translations', component: TranslationsComponent},
  {path: 'translations/:translation_id', component: TranslationsComponent},
  {path: 'gpt/:gpt_id', component: GptsComponent},
  {path: 'food/:user_id', component: FoodReportPageComponent},
  {path: '', component: GptsComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
