import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iGpts} from "../../models/interfaces";

@Component({
  selector: 'app-gpts',
  templateUrl: './gpts.component.html',
  styleUrls: ['./gpts.component.scss']
})
export class GptsComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }

  public STATUS_DONE_DEBOUNCE = 2;
  public STATUS_DONE = 1;
  public STATUS_AWAIT = 0;
  public STATUS_CANCEL = -1;
  public STATUS_SHOW_ALL = -2;
  public STATUS_SHOW_PAGE = -3;

  public statuses = {
    '0': '⌛',
    '1': '✔',
    '-1': '❌',
    '2': '🧱'
  };

  public status = 1;
  public page = 1;
  public perPage = 100;
  public onlyWithErrors:number = 0;

  public isMobile:boolean = false;

  public gpts:iGpts[] = [];
  // public statuses:any[] = [];
  public gptsData:any = {};

  public variants:any = {
    data: []
  };

  public shows = {};

  public currentGpts:iGpts = null;
  public lastCopied = 0;

  public newGpt: iGpts = null;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }

    this.route.params.subscribe(p => {
      if(!p['gpt_id']){
        return;
      }
      const qb = new QueryBuilder()
          .with([
            'user',
            'gptsVariant',
            'calledFunctions.func'
          ]);
      this.query.one('gpt', p['gpt_id'], qb).then(res => {
        this.selectGpt(res);
      });
    })

    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = this.STATUS_SHOW_PAGE;
    }
    else {
      this.loadGpts();
    }
    // this.loadGptsVariants();
  }

  selectGpt(gpt:iGpts){
    this.currentGpts = gpt;
    this.newGpt = JSON.parse(JSON.stringify(gpt));
  }

  switchGpts(){
    this.onlyWithErrors++;
    if(this.onlyWithErrors > 2){
      this.onlyWithErrors = 0;
    }
  }

  loadGpts(status = this.STATUS_SHOW_ALL, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder()
        .orderBy('gpt_id', 'desc')
        .page(this.page)
        .per_page(this.perPage)
        .with([
          'user',
          'gptsVariant',
          'gptResponses',
          'gptResponse',
          'gptRequests.error',
          'calledFunctions.func'
        ])
        .where('parent_gpt_id', '=', 0)
    ;
    if(this.status >= this.STATUS_CANCEL){
      qb.where('status', '=', this.status);
    }
    if(this.onlyWithErrors === 1){
      qb.where('gptRequests.error.user_id', '>', 0);
    }

    if(this.onlyWithErrors === 2){
      qb.where('gptsVariant.step', '=', 2);
    }

    this.query.all('gpt', qb).then(res => {
      this.gpts = res.data;
      this.gptsData = res;
      if(callback){
        callback();
      }
    });
  }

  updateData(){
    this.query.update('gpt', this.currentGpts.gpt_id, this.newGpt).then(res => {
      this.currentGpts = res;
    });
  }

  nextRequest(gpt:iGpts){
    gpt.gpts_request_number++;
    if(gpt.gpts_request_number >= gpt.gpt_requests.length ){
      gpt.gpts_request_number = gpt.gpt_requests.length-1;
    }
  }

  prevRequest(gpt:iGpts){
    gpt.gpts_request_number--;
    if(gpt.gpts_request_number < 0){
      gpt.gpts_request_number = 0;
    }
  }

}
