import { Injectable, PLATFORM_ID, Inject, Optional } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CONFIG } from "../store/config";
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private static readonly CLIENT = 0;
    private static readonly SERVER = 1;

    private type = -1;

    public itemsGetter: (paginator, filter) => any;

    constructor (
        @Inject(PLATFORM_ID) platformId: Object,
        @Optional() @Inject(RESPONSE) private response,
        private router: Router )
    {
        if (isPlatformBrowser(platformId))
            //Client only code.
            this.type = DataService.CLIENT;
        else
            //Server only code.
            this.type = DataService.SERVER;
    }

    get isServer() { return this.type === DataService.SERVER }

    get isClient() { return this.type === DataService.CLIENT }


    send404(){
        if (this.isServer) {
            this.response.status(404);
        }
        this.router.navigateByUrl("/pathToYourNotFoundView", { skipLocationChange: true });
    }
}