<div class="app-wrapper">

<h1 class="title">{{ foods[0]?.user_timezone?.name }}'s Food Diary</h1>

<div class="reports">
	<app-food-report *ngFor="let day of dates" [foods]="days[day]" [day]="day"></app-food-report>
</div>

<!--<h1 class="title" *ngIf="dates.length" (click)="loadByDate(dates[dates.length-1])">Load more</h1>-->

</div>