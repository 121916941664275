import { Component } from '@angular/core';
import {AccountService} from "../query/account";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';

  constructor(public account: AccountService) {
    this.account.current();
  }

}
