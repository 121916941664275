import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {Router} from "@angular/router";

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  public email:string = '';
  public phone:string = '';
  public name:string = '';
  public password:string = '';
  public save:boolean = false;
  public errors:any = [];
  public showCode:boolean = false;
  public code:string = '';

  @Input() callback;

  constructor(public account:AccountService, private router:Router) { }

  ngOnInit(): void {
    this.account.current();
  }

  login(){
    this.errors = [];
    this.account.login({
      email: this.email,
      password: this.password
    }, true).then(res => {
      this.router.navigateByUrl('/');
    }).catch(err => {
      // for(let errIndex of Object.keys(err.error)){
      //   this.errors.push(err.error[errIndex][0]);
      // }
      this.errors.push('Не верный емэйл или пароль');
    });
  }

  register(){
    this.account.register({email:this.email, password:this.password, name: this.name, phone:this.phone, code: this.code}, this.save).then(res => {
      this.showCode = true;
      if(res['api_token']){
        if(!this.callback){
          this.router.navigateByUrl('/');
        } else {
          this.callback();
        }

      }
    }).catch(res => {
      this.errors = res.error.error;
    })
  }

}
