
<div class="columns" id="mail-app">
    <aside class="column is-2 aside hero is-fullheight" *ngIf="!isMobile || (isMobile && status == -2)">
        <div>
            <div class="main">
                <a class="item" href="/"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Запросы</span></a>
                <a class="item active" (click)="loadUser(-1, 1);currentUser = null;users=[];"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Юзеры</span></a>
                <a class="item" href="/settings"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Настройки</span></a>
                <a class="item" href="/translations"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Локализация</span></a>
<!--                <a class="item" href="/barcodes" ><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Штрихкоды</span></a>-->
                <a class="item" href="/barcodes-users" ><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Штрихкоды</span></a>
            </div>
        </div>
    </aside>
    <div class="column messages hero is-fullheight" id="message-feed" *ngIf="!currentUser && (!isMobile || (isMobile && status > -2))">
        <div class="action-buttons">
            <div class="control is-grouped pg">
                <button class="button" *ngIf="isMobile" (click)="status = -2;">Вернуться назад</button>
                <div class="title">Список пользователей (страница {{ page }} из {{ usersData?.last_page }})</div>
                <a class="button " [class.is-link]="!isMobile" (click)="loadUser(this.status, page-1)"><i class="fa fa-chevron-left"></i></a>
                <a class="button " [class.is-link]="!isMobile" (click)="loadUser(this.status, page+1)"><i class="fa fa-chevron-right"></i></a>
            </div>
        </div>

        <div class="inbox-messages" id="inbox-messages">
            <a *ngFor="let user of users" target="_blank" href="/users/{{ user.user_id }}" class="card clickable block" >
<!--                <div [class.active]="currentUser?.user_id == user.user_id" (click)="selectUser(user)">open</div>-->
                <div class="card-content">
                    <div class="msg-header">

                        <span class="msg-timestamp">Создан:{{ user.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</span>
<!--                        <span class="msg-timestamp">Оплачен до:{{ user.paid_per | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</span>-->
<!--                        <span class="msg-attachment"><i class="fa fa-paperclip"></i></span>-->
                    </div>
                    <div class="msg-subject">
                        <span class="msg-subject"><strong id="fake-subject-1">ID: {{ user.user_id }} / {{ user.messenger_and_id }} Name: {{ user.name }} </strong></span>
                    </div>
                    <div class="msg-snippet">
                        <p id="fake-snippet-1">{{ user.email }}</p>
                    </div>
                </div>
            </a>
        </div>
    </div>
    <div class="column is-fullheight" [class.message]="!isMobile" *ngIf="currentUser" id="message-pane">

        <div class="">
            <div class="top">
                <div class="address">
                    <div class="name">
                        <div class="field is-grouped is-grouped-multiline" >
                            <p class="control">
                                <a class="button" (click)="currentUser = null;">Вернуться назад</a>
                            </p>
                        </div>
                    </div>

                </div>
                <hr>
                <div class="content">

                    <table class="table is-bordered">
                        <tr>
                            <td>Номер</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" type="text" [(ngModel)]="newUser.user_id">
                                    </div>
                                    <div class="control" *ngIf="currentUser.user_id != newUser.user_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Емэйл</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.email">
                                    </div>
                                    <div class="control" *ngIf="currentUser.email != newUser.email" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Имя</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.name">
                                    </div>
                                    <div class="control" *ngIf="currentUser.name != newUser.name" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Пароль (всегда скрыт)</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.password">
                                    </div>
                                    <div class="control" *ngIf="currentUser.password != newUser.password" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Оплачено до</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <span class="clickable" [owlDateTimeTrigger]="dt1">{{ newUser.paid_per | date:'dd.MM.YYYY HH:mm':'GMT+3'}}</span>
                                        <input [owlDateTime]="dt1" style="visibility:hidden;width: 1px;height: 1px;"  [(ngModel)]="newUser.paid_per">
                                        <owl-date-time #dt1></owl-date-time>
                                    </div>
                                    <div class="control" *ngIf="currentUser.paid_per != newUser.paid_per" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Триальные токены</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.gpt_tokens_trial">
                                    </div>
                                    <div class="control" *ngIf="currentUser.gpt_tokens_trial != newUser.gpt_tokens_trial" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Токены</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.gpt_tokens">
                                    </div>
                                    <div class="control" *ngIf="currentUser.gpt_tokens != newUser.gpt_tokens" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Роль</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="newUserRole" [(ngModel)]="newUser.role">
                                            Пользователь
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="2" name="newUserRole" [(ngModel)]="newUser.role">
                                            Админ
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentUser.role != newUser.role" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Пол</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.gender">
                                    </div>
                                    <div class="control" *ngIf="currentUser.gender != newUser.gender" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Рост</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.height">
                                    </div>
                                    <div class="control" *ngIf="currentUser.height != newUser.height" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Вес</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.weight">
                                    </div>
                                    <div class="control" *ngIf="currentUser.weight != newUser.weight" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Уровень активности</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.activity_level">
                                    </div>
                                    <div class="control" *ngIf="currentUser.activity_level != newUser.activity_level" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Цели</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.goals">
                                    </div>
                                    <div class="control" *ngIf="currentUser.goals != newUser.goals" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>special_requirements</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.special_requirements">
                                    </div>
                                    <div class="control" *ngIf="currentUser.special_requirements != newUser.special_requirements" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>calories</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.calories">
                                    </div>
                                    <div class="control" *ngIf="currentUser.calories != newUser.calories" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>proteins</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.proteins">
                                    </div>
                                    <div class="control" *ngIf="currentUser.proteins != newUser.proteins" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>fats</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.fats">
                                    </div>
                                    <div class="control" *ngIf="currentUser.fats != newUser.fats" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>carbs</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.carbs">
                                    </div>
                                    <div class="control" *ngIf="currentUser.carbs != newUser.carbs" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>age</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.age">
                                    </div>
                                    <div class="control" *ngIf="currentUser.age != newUser.age" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>version</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.version">
                                    </div>
                                    <div class="control" *ngIf="currentUser.version != newUser.version" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>lang</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.lang">
                                    </div>
                                    <div class="control" *ngIf="currentUser.lang != newUser.lang" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>step</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.step">
                                    </div>
                                    <div class="control" *ngIf="currentUser.step != newUser.step" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>timezone</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.timezone">
                                    </div>
                                    <div class="control" *ngIf="currentUser.timezone != newUser.timezone" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>country</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newUser.country">
                                    </div>
                                    <div class="control" *ngIf="currentUser.country != newUser.country" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Дата создания</td>
                            <td>{{ currentUser.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>
                        <tr>
                            <td>Дата изменения</td>
                            <td>{{ currentUser.updated_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>

                        <tr>
                            <td (click)="deletionAllow = true;">Удалить?</td>
                            <td>
                                <a *ngIf="deletionAllow" (click)="deleteUser(); deletionAllow = false;">Удалить</a>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">

                                <nav class="is-small block is-2" role="navigation" aria-label="pagination">
                                    <a class="pagination-previous" (click)="loadGpts(currentUser, gptsPage-1)"><-</a>
                                    Страница {{ gptsPage }} из {{ gpts.last_page || 0 }}
                                    <a  class="pagination-next" (click)="loadGpts(currentUser, gptsPage+1)">-></a>
                                </nav>
                                <nav class="is-small block is-2" role="navigation" aria-label="pagination">
                                    <label for="reloadTimeout">Частота обновления в мс</label>
                                    <input id="reloadTimeout" type="text" class="input" [(ngModel)]="reloadTimeout" (change)="dropTimer()">
                                </nav>

                                <audio #audio></audio>

                                <article class="message" style="padding: 0;" *ngFor="let gpt of gpts.data" [class.pl-5]="gpt.status === STATUS_DONE_DEBOUNCE">


                                    <div class="message-body" >

                                        <div class="msg-header">
                                            <span class="msg-from">
                                                <small>
                                                    {{ gpt.role ? 'Assistant':"User" }} ({{ statuses[gpt.status] }})
                                                    <a *ngIf="gpt.file_path" (click)="audio.src = gpt.file_path;audio.play();$event.preventDefault();">🔊</a>
                                                    <a (click)="shows[gpt.gpt_id] = !shows[gpt.gpt_id];gpt.gpts_request_number = 0;">{{ gpt.created_at | date:'dd.MM.YYYY HH:mm:ss' }} -> {{ gpt.updated_at | date:'dd.MM.YYYY HH:mm:ss' }}</a><br>
                                                    <span *ngIf="gpt.gpt_tokens_request || gpt.gpt_tokens_response">Токены: {{ gpt.gpt_tokens_request }} / {{ gpt.gpt_tokens_response }}</span><br>
                                                </small>
                                            </span>

                                            <span>

                                                <span *ngIf="gpt.flags">
                                                    <span class="tag">{{ gpt.flags }}</span>
                                                </span>
                                                <span>
                                                    <span class="tag">{{ gpt.gpts_variant?.name }}</span>
                                                </span>

                                                <span *ngFor="let func of gpt.called_functions;let i = index;">
                                                    <span *ngIf="i-1 < 0 || gpt.called_functions[i].func.name != gpt.called_functions[i-1].func.name" class="tag is-{{ gpt.called_functions[i].func.color }}">{{ gpt.called_functions[i].func.name }}</span>
                                                </span>
                                            </span>

                                        </div>
                                        <br>
                                        <p (click)="shows[gpt.gpt_id] = !shows[gpt.gpt_id]">
                                            {{ gpt.message }}
                                        </p>


                                        <div class="block" [class.pl-5]="sub.status === STATUS_DONE_DEBOUNCE" *ngFor="let sub of gpt.gpt_responses">
                                            <small>
                                                {{ sub.role ? 'Assistant':"User" }} ({{ statuses[sub.status] }})
                                                <a *ngIf="sub.file_path" (click)="audio.src = sub.file_path;audio.play();$event.preventDefault();">🔊</a>
                                                <span *ngIf="sub.gpt_tokens_request || sub.gpt_tokens_response">Токены: {{ sub.gpt_tokens_request }} / {{ sub.gpt_tokens_response }}</span><br>
                                                <!--                                {{ sub.created_at | date:'dd.MM.YYYY HH:mm:ss' }} -> {{ sub.updated_at | date:'dd.MM.YYYY HH:mm:ss' }}-->
                                            </small>
                                            <br>
                                            <p>
                                                {{ sub.message }}
                                            </p>
                                        </div>

                                    </div>



                                    <div class="msg-snippet" *ngIf="shows[gpt.gpt_id] && gpt.gpt_requests.length">
                                        <table class="table" style="white-space: pre-line">
                                            <tr>
                                                <td colspan="2">
                                                    Запрос в GPT<br>
                                                    <div *ngIf="gpt.gpt_requests?.length > 1">
                                                        <a (click)="nextRequest(gpt)"> <- </a>
                                                        {{ gpt.gpts_request_number || 0 }}
                                                        <a (click)="prevRequest(gpt)"> -> </a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let req of gpt.gpt_requests[gpt.gpts_request_number || 0]?.request">
                                                <td>{{ req.role }}</td>
                                                <td style="font-size: small">{{ req.content }}</td>
                                            </tr>

                                            <tr *ngIf="gpt.gpt_requests[gpt.gpts_request_number || 0]?.response?.length">
                                                <td style="color:red;">
                                                    assistant
                                                </td>
                                                <td style="font-size: small">{{ gpt.gpt_requests[gpt.gpts_request_number || 0]?.response[0]?.content }}</td>
                                            </tr>

                                            <tr *ngIf="gpt.gpt_requests[gpt.gpts_request_number || 0]?.error" >
                                                <td style="color:red;" (click)="gpt.gpt_requests[gpt.gpts_request_number || 0].error.show = !gpt.gpt_requests[gpt.gpts_request_number || 0].error.show">
                                                    error
                                                </td>
                                                <td style="font-size: small">
                                                    {{ gpt.gpt_requests[gpt.gpts_request_number || 0].error.show ? gpt.gpt_requests[gpt.gpts_request_number || 0]?.error.error : 'hidden' }}
                                                    <hr>
                                                    {{ gpt.gpt_requests[gpt.gpts_request_number || 0].error.show ? gpt.gpt_requests[gpt.gpts_request_number || 0]?.error.trace : 'hidden' }}
                                                </td>
                                            </tr>

                                        </table>
                                    </div>
                                </article>
                            </td>
                        </tr>


                    </table>

                </div>
            </div>
        </div>
    </div>
</div>