<h3 class="date">{{ day | date:'mediumDate' }}</h3>

<div class="main-info filled">
	<div class="main-info__cal">
		<div class="main-info__cal-titles">
			<span>{{ calories }} CAL</span>
			<span>{{ foods[0].user_timezone.calories - calories }} LEFT</span>
		</div>
		<app-gauge
				[value]="calories * 100 / (foods[0].user_timezone.calories)"
				[color]="calories > foods[0].user_timezone.calories ? GaugeColor.Red : GaugeColor.Green"></app-gauge>
	</div>

	<div class="main-info__other">
		<ng-container
			[ngTemplateOutlet]="consumingDetail"
			[ngTemplateOutletContext]="{ unit: 'PRT', current: proteins, total: foods[0].user_timezone.proteins }">
		</ng-container>

		<ng-container
			[ngTemplateOutlet]="consumingDetail"
			[ngTemplateOutletContext]="{ unit: 'FAT', current: fats, total: foods[0].user_timezone.fats }">
		</ng-container>

		<ng-container
			[ngTemplateOutlet]="consumingDetail"
			[ngTemplateOutletContext]="{ unit: 'CARB', current: carbs, total: foods[0].user_timezone.carbs }">
		</ng-container>

		<ng-template #consumingDetail let-unit="unit" let-current="current" let-total="total">
			<div class="consuming-detail">
				{{ unit }}
		
				<app-gauge
					class="consuming-detail__gauge"
					[value]="current * 100 / total"
					[color]="current > total ? GaugeColor.Red : GaugeColor.Green">
				</app-gauge>
		
				<div>{{ current }} / {{ total }}</div>
			</div>
		</ng-template>
	</div>
</div>

<div class="detailed-info filled">
	<h5 class="detailed-info__title">FOOD LIST</h5>

	<div class="detailed-info__list">
		<ng-container *ngFor="let food of foods"
			[ngTemplateOutlet]="foodDetail"
			[ngTemplateOutletContext]="{
				cal: food['calories'],
				prt: food['proteins'],
				fat: food['fats'],
				carb: food['carbs'],
				name: food['title'],
				detail: '',
				image: ''
			}">
		</ng-container>


		<ng-template
			#foodDetail
			let-cal="cal"
			let-prt="prt"
			let-fat="fat"
			let-carb="carb"
			let-name="name"
			let-detail="detail"
			let-image="image"
		>
			<div class="food-detail">
				<span class="food-detail__image">{{ image }}</span>

				<div class="food-detail__title">
					<span>{{ name }}</span>
<!--					<br>-->
<!--					<span>{{ detail }}</span>-->
				</div>

				<div class="food-detail__numbers">
					<b>{{ cal }} CAL</b>
					<br>
					<span>{{ prt }} / {{ fat }} / {{ carb }}</span>
				</div>
			</div>
		</ng-template>
	</div>
</div>
