
<div class="columns" id="mail-app">
    <aside class="column is-2 aside hero is-fullheight" *ngIf="!isMobile || (isMobile && status == -2)">
        <div>
            <div class="main">
                <a class="item" href="/"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Запросы</span></a>
                <a class="item" href="/users"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Юзеры</span></a>
                <a class="item" href="/settings"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Настройки</span></a>
                <a class="item" href="/translations"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Локализация</span></a>
                <a class="item active" (click)="loadGpts(-1, 1);currentGpts = null;gpts=[];" ><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Штрихкоды</span></a>
                <a class="item" href="/barcodes-users" ><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Штрихкоды</span></a>

            </div>
        </div>
    </aside>
    <div class="column messages hero is-fullheight" id="message-feed" *ngIf="!currentGpts && (!isMobile || (isMobile && status > -2))">
        <div class="action-buttons">
            <div class="control is-grouped pg">
                <button class="button" *ngIf="isMobile" (click)="status = -2;">Вернуться назад</button>
                <div class="title">Список заявок (страница {{ page }} из {{ gptsData?.last_page }})</div>
                <a class="button " [class.is-link]="!isMobile" (click)="loadGpts(this.status, page-1)"><i class="fa fa-chevron-left"></i></a>
                <a class="button " [class.is-link]="!isMobile" (click)="loadGpts(this.status, page+1)"><i class="fa fa-chevron-right"></i></a>
            </div>
        </div>

        <div class="inbox-messages" id="inbox-messages">

<!--            <div class="card clickable block" (click)="createVariant()">-->
<!--                <div class="card-content">-->
<!--                    <div class="msg-header">-->

<!--                        <span class="msg-timestamp"></span>-->
<!--                    </div>-->
<!--                    <div class="msg-subject">-->
<!--                        <span class="msg-subject"><strong >Нажмите что бы создать новый</strong></span>-->
<!--                    </div>-->
<!--                    <div class="msg-snippet">-->
<!--                        <p ></p>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->


            <div *ngFor="let gpt of gpts" class="card clickable block" [class.active]="currentGpts?.barcode_id == gpt.barcode_id" >
                <div class="card-content">
                    <div class="msg-header">

                        <span class="msg-timestamp" (click)="selectGpt(gpt);$event.preventDefault();">{{ gpt.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</span>
                        <span class="msg-attachment"><a (click)="deleteVariant(gpt);$event.preventDefault();">Удалить</a> </span>
                    </div>

                    <div class="msg-snippet" (click)="selectGpt(gpt);$event.preventDefault();">
                        <p id="fake-snippet-1">{{ gpt.barcode }} {{ gpt.provider }} {{ gpt.json?.product_name || 'Не найден' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="column hero is-fullheight" [class.message]="!isMobile" *ngIf="currentGpts" id="message-pane">

        <div class=""  [class.box]="!isMobile">
            <div class="top">
                <div class="address">
                    <div class="name">
                        <div class="field is-grouped is-grouped-multiline" >
                            <p class="control">
                                <a class="button" (click)="currentGpts = null;loadGpts(-1, 1);currentGpts = null;gpts=[];">Вернуться назад</a>
                            </p>
                        </div>
                    </div>
                    <div class="name">
                        {{ currentGpts.barcode_id }}
                    </div>

                </div>
                <hr>
                <div class="content">

                    <table class="table is-bordered">
                        <tr>
                            <td>Номер</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" type="text" [(ngModel)]="newGpt.barcode_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.barcode_id != newGpt.barcode_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>barcode</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newGpt.barcode">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.barcode != newGpt.barcode" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>provider</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" [(ngModel)]="newGpt.provider">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.provider != newGpt.provider" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>json_data</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <textarea class="textarea" [(ngModel)]="newGpt.json_data"></textarea>
                                    </div>
                                    <div class="control" *ngIf="currentGpts.json_data != newGpt.json_data" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Дата создания</td>
                            <td>{{ currentGpts.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>
                        <tr>
                            <td>Дата изменения</td>
                            <td>{{ currentGpts.updated_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>

                    </table>

                </div>
            </div>
        </div>
    </div>
</div>