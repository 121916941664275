import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GaugeColor } from './gauge.types';

const COLOR_MAP = {
	[GaugeColor.Green]: '#48D07F',
	[GaugeColor.Red]: '#F1502D',
}

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GaugeComponent {
	readonly ColorMap = COLOR_MAP;

	@Input() value = 50;
	@Input() color: GaugeColor = GaugeColor.Green;

	parseValue(): string {
		let value = this.value;

		if (value > 100) value = 100;
		if (value < 0) value = 0;

		return value + '%';
	}
}
