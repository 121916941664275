<!--<nav class="navbar" role="navigation" aria-label="main navigation">-->
<!--    <div class="navbar-brand">-->
<!--        <a class="navbar-item" href="/">-->
<!--&lt;!&ndash;            <img src="/assets/logos/facebook_cover_photo_1.png">&ndash;&gt;-->
<!--        </a>-->

<!--        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">-->
<!--            <span aria-hidden="true"></span>-->
<!--            <span aria-hidden="true"></span>-->
<!--            <span aria-hidden="true"></span>-->
<!--        </a>-->
<!--    </div>-->

<!--    <div id="navbarBasicExample" class="navbar-menu">-->
<!--        <div class="navbar-start">-->


<!--            <a href="/code" class="navbar-item">-->
<!--                Создать заявку-->
<!--            </a>-->

<!--&lt;!&ndash;            <div class="navbar-item has-dropdown is-hoverable">&ndash;&gt;-->
<!--&lt;!&ndash;                <a class="navbar-link">&ndash;&gt;-->
<!--&lt;!&ndash;                    More&ndash;&gt;-->
<!--&lt;!&ndash;                </a>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="navbar-dropdown">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a class="navbar-item">&ndash;&gt;-->
<!--&lt;!&ndash;                        About&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    <a class="navbar-item">&ndash;&gt;-->
<!--&lt;!&ndash;                        Jobs&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    <a class="navbar-item">&ndash;&gt;-->
<!--&lt;!&ndash;                        Contact&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                    <hr class="navbar-divider">&ndash;&gt;-->
<!--&lt;!&ndash;                    <a class="navbar-item">&ndash;&gt;-->
<!--&lt;!&ndash;                        Report an issue&ndash;&gt;-->
<!--&lt;!&ndash;                    </a>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--        </div>-->

<!--        <div class="navbar-end" *ngIf="!account.user">-->
<!--            <div class="navbar-item">-->
<!--                <div class="buttons">-->
<!--                    <a href="/login" class="button is-primary">-->
<!--                        <strong>Sign up or log in</strong>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        <div class="navbar-end" *ngIf="account.user">-->
<!--            <div class="navbar-item">-->
<!--                <div class="buttons">-->
<!--                    <a href="/profile" class="button is-primary">-->
<!--                        <strong>Profile</strong>-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</nav>-->

<router-outlet></router-outlet>