
<div class="columns" id="mail-app">
    <aside class="column is-2 aside hero is-fullheight" *ngIf="!isMobile || (isMobile && status == STATUS_SHOW_PAGE)">
        <div>
            <div class="main">
                <a class="item active" (click)="loadGpts(STATUS_SHOW_ALL, 1);currentGpts = null;gpts=[];"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Запросы</span></a>
                <a class="item" href="/users"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Юзеры</span></a>
                <a class="item" href="/settings"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Настройки</span></a>
                <a class="item" href="/translations"><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Локализация</span></a>
<!--                <a class="item" href="/barcodes" ><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Штрихкоды</span></a>-->
                <a class="item" href="/barcodes-users" ><span class="icon"><i class="fa fa-inbox"></i></span><span class="name">Штрихкоды</span></a>
            </div>
        </div>
    </aside>
    <div class="column messages hero is-fullheight" id="message-feed" *ngIf="!currentGpts && (!isMobile || (isMobile && status > STATUS_SHOW_PAGE))">
        <div class="action-buttons">
            <div class="control is-grouped pg">
                <button class="button" *ngIf="isMobile" (click)="status = STATUS_SHOW_PAGE;">Вернуться назад</button>
                <button class="button" (click)="switchGpts();loadGpts(this.status, 1)">{{ ['Выведены все','Выведены только ошибки', 'Только step 2'][onlyWithErrors] }}</button>
                <div class="title">Список заявок (страница {{ page }} из {{ gptsData?.last_page }})</div>
                <a class="button " [class.is-link]="!isMobile" (click)="loadGpts(this.status, page-1)"><i class="fa fa-chevron-left"></i></a>
                <a class="button " [class.is-link]="!isMobile" (click)="loadGpts(this.status, page+1)"><i class="fa fa-chevron-right"></i></a>
            </div>
        </div>

        <audio #audio></audio>

        <div class="inbox-messages" id="inbox-messages">
            <div *ngFor="let gpt of gpts" class="card block" [class.active]="currentGpts?.gpt_id == gpt.gpt_id" >
                <div class="card-content">
                    <div class="msg-header">
                        <span class="msg-from">

                            <small>
                                <span *ngIf="gpt.role">Assistant</span>
                                <span *ngIf="!gpt.role"><a href="/users/{{ gpt.user_id }}">{{ [gpt.user_id, gpt.user?.name, gpt.user?.email].join(' / ') }}</a></span>
                                ({{ statuses[gpt.status] }})
                                <a *ngIf="gpt.file_path" (click)="audio.src = gpt.file_path;audio.play();$event.preventDefault();">🔊</a>
                                <span *ngIf="gpt.gpt_tokens_request || gpt.gpt_tokens_response">Токены: {{ gpt.gpt_tokens_request }} / {{ gpt.gpt_tokens_response }}</span><br>
                                <a (click)="shows[gpt.gpt_id] = !shows[gpt.gpt_id];gpt.gpts_request_number = 0;">{{ gpt.created_at | date:'dd.MM.YYYY HH:mm:ss' }} -> {{ gpt.updated_at | date:'dd.MM.YYYY HH:mm:ss' }}</a><br>
                            </small>

                        </span>

                        <span>
                            <span *ngIf="gpt.flags">
                                <span class="tag">{{ gpt.flags }}</span>
                            </span>
                            <span>
                                <span class="tag">{{ gpt.gpts_variant?.name }}</span>
                            </span>
                            <span *ngFor="let func of gpt.called_functions;let i = index;">
                                <span *ngIf="i-1 < 0 || gpt.called_functions[i].func.name != gpt.called_functions[i-1].func.name" class="tag is-{{ gpt.called_functions[i].func.color }}">{{ gpt.called_functions[i].func.name }}</span>
                            </span>

                        </span>


                    </div>

                    <div class="msg-snippet">
                        <br>
                        <p id="fake-snippet-1">{{ gpt.message }}</p>

                        <br>
                        <div class="block" [class.pl-5]="sub.status === STATUS_DONE_DEBOUNCE" *ngFor="let sub of gpt.gpt_responses">
                            <small>
                                {{ sub.role ? 'Assistant':"User" }} ({{ statuses[sub.status] }})
                                <a *ngIf="sub.file_path" (click)="audio.src = sub.file_path;audio.play();$event.preventDefault();">🔊</a>
                                <span *ngIf="sub.gpt_tokens_request || sub.gpt_tokens_response">Токены: {{ sub.gpt_tokens_request }} / {{ sub.gpt_tokens_response }}</span><br>
<!--                                {{ sub.created_at | date:'dd.MM.YYYY HH:mm:ss' }} -> {{ sub.updated_at | date:'dd.MM.YYYY HH:mm:ss' }}-->
                            </small>
                            <br>
                            <p>
                                {{ sub.message }}
                            </p>
                        </div>
                    </div>

                    <div class="msg-snippet" *ngIf="shows[gpt.gpt_id] && gpt.gpt_requests.length">
                        <table class="table" style="white-space: pre-line">
                            <tr>
                                <td colspan="2">
                                    Запрос в GPT<br>
                                    <div *ngIf="gpt.gpt_requests?.length > 1">
                                        <a (click)="nextRequest(gpt)"> <- </a>
                                        {{ gpt.gpts_request_number }}
                                        <a (click)="prevRequest(gpt)"> -> </a>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngFor="let req of gpt.gpt_requests[gpt.gpts_request_number]?.request">
                                <td>{{ req.role }}</td>
                                <td style="font-size: small">{{ req.content }}</td>
                            </tr>

                            <tr *ngIf="gpt.gpt_requests[gpt.gpts_request_number]?.response?.length">
                                <td style="color:red;">
                                    assistant
                                </td>
                                <td style="font-size: small">{{ gpt.gpt_requests[gpt.gpts_request_number]?.response[0]?.content }}</td>
                            </tr>

                            <tr *ngIf="gpt.gpt_requests[gpt.gpts_request_number]?.error" >
                                <td style="color:red;" (click)="gpt.gpt_requests[gpt.gpts_request_number].error.show = !gpt.gpt_requests[gpt.gpts_request_number].error.show">
                                    error
                                </td>
                                <td style="font-size: small">
                                    {{ gpt.gpt_requests[gpt.gpts_request_number].error.show ? gpt.gpt_requests[gpt.gpts_request_number]?.error.error : 'hidden' }}
                                    <hr>
                                    {{ gpt.gpt_requests[gpt.gpts_request_number].error.show ? gpt.gpt_requests[gpt.gpts_request_number]?.error.trace : 'hidden' }}
                                </td>
                            </tr>

                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="column hero is-fullheight" [class.message]="!isMobile" *ngIf="currentGpts" id="message-pane">

        <div class=""  [class.box]="!isMobile">
            <div class="top">
                <div class="address">
                    <div class="name">
                        <div class="field is-grouped is-grouped-multiline" >
                            <p class="control">
                                <a class="button" (click)="currentGpts = null;">Вернуться назад</a>
                            </p>
                        </div>
                    </div>
                    <div class="name">
                        {{ currentGpts.gpt_id }}
                    </div>

                </div>
                <hr>
                <div class="content">

                    <table class="table is-bordered">
                        <tr>
                            <td>Номер</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input class="input" type="text" [(ngModel)]="newGpt.gpt_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.gpt_id != newGpt.gpt_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>#important</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="1" name="important" [(ngModel)]="newGpt.important">
                                            Важное
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="important" [(ngModel)]="newGpt.important">
                                            Не важное
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentGpts.important != newGpt.important" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>#dashboard</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="1" name="dashboard" [(ngModel)]="newGpt.dashboard">
                                            Это dashboard
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="dashboard" [(ngModel)]="newGpt.dashboard">
                                            Не dashboard
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentGpts.dashboard != newGpt.dashboard" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Роль</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="1" name="role" [(ngModel)]="newGpt.role">
                                            Assistant
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="role" [(ngModel)]="newGpt.role">
                                            User
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentGpts.role != newGpt.role" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Сообщение</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <textarea class="textarea" [(ngModel)]="newGpt.message"></textarea>
                                    </div>
                                    <div class="control" *ngIf="currentGpts.message != newGpt.message" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>ID юзера</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.user_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.user_id != newGpt.user_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>ID варианта запроса</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.gpts_variant_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.gpts_variant_id != newGpt.gpts_variant_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Статус</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <label class="radio">
                                            <input type="radio" [value]="2" name="status" [(ngModel)]="newGpt.status">
                                            Выполнено с дебаунсом {{ statuses['2'] }}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="1" name="status" [(ngModel)]="newGpt.status">
                                            Выполнено {{ statuses['1'] }}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="0" name="status" [(ngModel)]="newGpt.status">
                                            Ожидает {{ statuses['0'] }}
                                        </label>
                                        <label class="radio">
                                            <input type="radio" [value]="-1" name="status" [(ngModel)]="newGpt.status">
                                            Отменено / Команда {{ statuses['-1'] }}
                                        </label>
                                    </div>

                                    <div class="control" *ngIf="currentGpts.status != newGpt.status" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Номер апдейта</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.update_number">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.update_number != newGpt.update_number" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Номер сессии</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.session_number">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.session_number != newGpt.session_number" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>ID главного сообщения</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.parent_gpt_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.parent_gpt_id != newGpt.parent_gpt_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Мессенжер</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.messenger">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.messenger != newGpt.messenger" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>ID сообщения в мессенжере</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input type="text" class="input" [(ngModel)]="newGpt.messenger_message_id">
                                    </div>
                                    <div class="control" *ngIf="currentGpts.messenger_message_id != newGpt.messenger_message_id" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Путь до аудиофайла</td>
                            <td>
                                <div class="field" >
                                    <div class="control">
                                        <input readonly type="text" class="input is-4" [(ngModel)]="newGpt.file_path">
                                        <audio controls *ngIf="currentGpts.file_path" [src]="currentGpts.file_path"></audio>
                                    </div>
                                    <div class="control" *ngIf="currentGpts.file_path != newGpt.file_path" >
                                        <a class="button" (click)="updateData()">Сохранить</a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>Токены запроса</td>
                            <td>{{ currentGpts.gpt_tokens_request }}</td>
                        </tr>

                        <tr>
                            <td>Токены ответа</td>
                            <td>{{ currentGpts.gpt_tokens_response }}</td>
                        </tr>

                        <tr>
                            <td>Дата создания</td>
                            <td>{{ currentGpts.created_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>
                        <tr>
                            <td>Дата изменения</td>
                            <td>{{ currentGpts.updated_at | date:'dd.MM.YYYY HH:mm':'GMT+3' }}</td>
                        </tr>


                    </table>

                </div>
            </div>
        </div>
    </div>
</div>