<!--<section class="section">-->
<!--    <div class="container">-->
<!--        <div class="columns" *ngIf="!account.user">-->
<!--            <div class="column">-->

<!--                <div class="field">-->
<!--                    <label class="label">Email</label>-->
<!--                    <div class="control">-->
<!--                        <input class="input" [(ngModel)]="email" type="text" placeholder="Your email">-->
<!--                    </div>-->
<!--                </div>-->


<!--                <div class="field" >-->
<!--                    <label class="label">Password</label>-->
<!--                    <div class="control">-->
<!--                        <input class="input" [(ngModel)]="password" type="password" placeholder="Your password">-->
<!--                    </div>-->
<!--                    <p *ngFor="let error of errors" class="help">{{ error }}</p>-->
<!--                </div>-->


<!--                <div class="control">-->
<!--                    <button class="button is-primary" (click)="login()">Login</button>-->
<!--                </div>-->
<!--            </div>-->

<!--        </div>-->

<!--        <div *ngIf="account.user">-->
<!--            <div class="control">-->
<!--                <button class="button is-primary" (click)="account.logout()">Logout</button>-->
<!--            </div>-->
<!--        </div>-->

<!--    </div>-->
<!--</section>-->

<section class="hero is-fullheight">
    <div class="hero-body has-text-centered fjcc">
        <div class="login">
            <img src="">
<!--            <form>-->
                <div class="field">
                    <div class="control">
                        <input [(ngModel)]="email" class="input is-medium is-rounded" type="email" placeholder="hello@example.com" autocomplete="username" required />
                    </div>
                </div>
                <div class="field">
                    <div class="control">
                        <input [(ngModel)]="password" class="input is-medium is-rounded" type="password" placeholder="**********" autocomplete="current-password" required />
                    </div>
                    <p *ngFor="let error of errors" class="help">{{ error }}</p>
                </div>
                <br />
                <button (click)="login()" class="button is-block is-fullwidth is-primary is-medium is-rounded">
                    Login
                </button>
<!--            </form>-->
            <br>
<!--            <nav class="level">-->
<!--                <div class="level-item has-text-centered">-->
<!--                    <div>-->
<!--                        <a href="#">Forgot Password?</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="level-item has-text-centered">-->
<!--                    <div>-->
<!--                        <a href="#">Create an Account</a>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </nav>-->
        </div>
    </div>
</section>
