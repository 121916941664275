



<table class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
    <thead>


    <tr>
        <td colspan="6">
            <div class="field" >
                <div class="control">
                    <span class="clickable" [owlDateTimeTrigger]="dt1">{{ from || 'click to set date from' }}</span>
                    <br><span class="clickable" *ngIf="from" (click)="from = null;">reset</span>
                    <input [owlDateTime]="dt1" style="visibility:hidden;width: 1px;height: 1px;"  [(ngModel)]="from" (dateTimeChange)="loadByDate()">
                    <owl-date-time #dt1></owl-date-time>
                </div>
            </div>
        </td>
    </tr>
    <tr>
        <td colspan="6">
            <div class="field" >
                <div class="control">
                    <span class="clickable" [owlDateTimeTrigger]="dt2">{{ to || 'click to set date to' }}</span>
                    <br><span class="clickable" *ngIf="to" (click)="to = null;">reset</span>
                    <input [owlDateTime]="dt2" style="visibility:hidden;width: 1px;height: 1px;"  [(ngModel)]="to" (dateTimeChange)="loadByDate()">
                    <owl-date-time #dt2></owl-date-time>
                </div>
            </div>
        </td>
    </tr>



    <tr>
        <th>Time</th>
        <th>Title</th>
        <th>Calories</th>
        <th>Proteins</th>
        <th>Fats</th>
        <th>Carbs</th>
    </tr>
    </thead>
    <tfoot>
    <tr>
        <th>Time</th>
        <th>Title</th>
        <th>Calories</th>
        <th>Proteins</th>
        <th>Fats</th>
        <th>Carbs</th>
    </tr>
    </tfoot>
    <tbody>

    <ng-container *ngFor="let food of foods;let i = index;">
        <tr class="is-selected" *ngIf="i == 0 || foods[i-1].date != food.date">
            <td colspan="6">{{ food.date }}</td>
        </tr>
        <tr>
            <td>{{ food.created_at | date:'dd.MM.YYYY HH:mm':'GMT'+food.timezone }}</td>
            <td>{{ food.title }}</td>
            <td>{{ food.calories }}</td>
            <td>{{ food.proteins }}</td>
            <td>{{ food.fats }}</td>
            <td>{{ food.carbs }}</td>
        </tr>
    </ng-container>


    </tbody>
</table>