import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QueryBuilder } from "./query-builder";
import { AccountService } from "./account";
import { CONFIG } from "../store/config";
import {environment} from "../environments/environment";


@Injectable()
export class QueryService {

    constructor(private http: HttpClient, private account: AccountService) { }

    private options(builder?: QueryBuilder) {

        let headers = new HttpHeaders();
        headers = headers.append('Enctype', 'multipart/form-data');
        headers = headers.append('Accept', 'application/json');
        if(this.account.token){
            headers = headers.append('Authorization', ('Bearer ' + this.account.token));
        }

        const data = {
            headers: headers
        };

        if (builder)
            data['params'] = builder.request;

        return data;
    }

    full(model: string) {
        return this.http.get(environment.domain + '/api/v1/full/' + model).toPromise();
    }

    model(model: string) {
        return this.http.get(environment.domain + '/api/v1/model/' + model);
    }

    models(model: string) {
        return this.http.get(environment.domain + '/api/v1/models/' + model);
    }

    fields(model: string) {
        return this.http.get(environment.domain + '/api/v1/fields/' + model).toPromise();
    }

    types(model: string) {
        return this.http.get(environment.domain + '/api/v1/types/' + model);
    }

    call<T = any>(model: string, method: string, data?: any, options: any = {}, withFiles = false): Promise<T> {

        // options.headers['Content-Type'] = 'multipart/form-data';
        const formData: FormData = new FormData();
        this.buildFormData(formData, data);
        // if(data){
        //     for(let name in data){
        //         if(Array.isArray(data[name])){
        //             for(let one in data[name]){
        //                 formData.append(name+'[]', data[name][one]);
        //             }
        //         } else {
        //             formData.append(name, data[name]);
        //         }
        //     }
        // }


        const res = data ?
            this.http.post(environment.domain + '/api/v1/call/' + model + '/' + method, formData, Object.assign({}, this.options(), options)) :
            this.http.get(environment.domain + '/api/v1/call/' + model + '/' + method, Object.assign({}, this.options(), options));
        return res.toPromise() as any;
    }

     buildFormData(formData, data, parentKey = null) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;

            formData.append(parentKey, value);
        }
    }

    httpGet(url: string) {
        return this.http.get(url, this.options()).toPromise();
    }

    httpPost(url: string, data) {

        const formData: FormData = new FormData();
        if(data){
            for(let name in data){
                if(Array.isArray(data[name])){
                    for(let one in data[name]){
                        formData.append(name+'[]', data[name][one]);
                    }
                } else {
                    formData.append(name, data[name]);
                }
            }
        }

        return this.http.post(url, formData, this.options());
    }

    //=====================================================================================

    one<T = any>(model: string, id: number, builder?: QueryBuilder) {
        const promise = this.http.get<T>(environment.domain + '/api/v1/' + model + '/' + id, this.options(builder)).toPromise();
        const params = builder ? builder.all : null;
        promise.then(_data => {
            this.insertMethods(model, _data);
            if (params && params['with'])
                this.insertMethodsIntoRelations(model, _data, params['with']);
        });
        return promise;
    }

    all<T = any>(model: string, builder?: QueryBuilder) {
        const promise = this.http.get<T>(environment.domain + '/api/v1/' + model, this.options(builder)).toPromise();
        promise.then(res => {
            for (let i in res['data']) {
                this.insertMethods(model, res['data'][i]);
            }
        });
        return promise;
    }

    first<T = any>(model: string, builder?: QueryBuilder) {
        const promise = this.http.get<T>(environment.domain + '/api/v1/' + model, this.options(builder)).toPromise();
        promise.then(res => {
            const data = res['data'];

            for (let key of Object.keys(res)) {
                delete res[key];
            }

            if (data.length > 0)
                for (let key of Object.keys(data[0])) {
                    res[key] = data[0][key];
                }
        });
        return promise;
    }

    create<T = any>(model: string, data?) {
        const formData: FormData = this.createFormData({ ...data });

        const promise = this.http.post<T>(environment.domain + '/api/v1/' + model, formData, this.options()).toPromise();
        promise.then(created_object => {
            this.insertMethods(model, created_object);
        });
        return promise;
    }

    update<T = any>(model: string, id: number, data) {
        const formData = this.createFormData({...data});
        formData.append('_method', 'PUT');
        
        const promise = this.http.post<T>(environment.domain + '/api/v1/' + model + '/' + id, formData, this.options()).toPromise();
        promise.then(_data => {
            this.insertMethods(model, _data);
        });
        return promise;
    }

    del<T = any>(model: string, id: number) {
        return this.http.delete<T>(environment.domain + '/api/v1/' + model + '/' + id, this.options()).toPromise();
    }

    private createFormData(data) {
        this.deleteMethods(data);
        const formData = new FormData();
        const keys = Object.keys(data);
        for (let i = 0; i < keys.length; ++i) {
            formData.append(keys[i], data[keys[i]]);
        }
        return formData;
    }

    private deleteMethods(data) {
        delete data.$update;
        delete data.$delete;
    }

    private insertMethods(model: string, obj) {
        this.insertUpdateMethod(model, obj);
        this.insertDeleteMethod(model, obj);
    }

    private insertMethodsIntoRelations(model: string, obj, _with) {
        // console.log(model, _with);
    }

    private insertUpdateMethod(model: string, obj) {
        obj['$update'] = (data?) => {

            if (!obj[model + '_id'] && model[model.length-1] === 's')
                model = model.slice(0, -1);

            const formData = this.createFormData({ ...(data || obj) });
            formData.append('_method', 'PUT');

            return this.http.post(environment.domain + '/api/v1/' + model + '/' + obj[model + '_id'], formData, this.options()).toPromise();
        };
    }

    private insertDeleteMethod(model: string, obj) {
        obj['$delete'] = () => {
            if (!obj[model + '_id'] && model[model.length-1] === 's')
                model = model.slice(0, -1);
            return this.http.delete(environment.domain + '/api/v1/' + model + '/' + obj[model + '_id'], this.options()).toPromise();
        }
    }
}