import { Component } from '@angular/core';
import {iDayFood, iFood, iRequest} from "../../models/interfaces";
import {QueryBuilder} from "../../query/query-builder";
import {AccountService} from "../../query/account";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryService} from "../../query/query";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-food-report-page',
  templateUrl: './food-report-page.component.html',
  styleUrls: ['./food-report-page.component.scss']
})
export class FoodReportPageComponent {


  public foods: iFood[] = [];

  public days: any = {};
  public dates = [];

  public from:any;

  public user_id = 0;

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }


  ngOnInit(): void {

    this.route.params.subscribe(p => {
      if(!p['user_id']){
        return;
      }

      this.user_id = p['user_id'];

      this.loadByDate();


    })


  }



  loadByDate(date = ''){
    const qb = new QueryBuilder()
        .scope('user,'+this.user_id)
        .appends(['date', 'timezone'])
        .orderBy('food_id', 'desc')
        .per_page(9999999)
    ;

    if(date){
      qb.scope('date,'+date )
    }


    this.query.all<iRequest<iFood>>('food', qb).then(res => {
      this.foods = res.data;
      for(let day of res.data){
        if(!this.days[day.date]){
          this.days[day.date] = [];
        }
        this.days[day.date].push(day);
      }

      this.dates = Object.keys(this.days);
    });
  }

}
