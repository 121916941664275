import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iGpts, iRequest, iUser} from "../../models/interfaces";

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }

  public status = 1;
  public page = 1;
  public perPage = 100;

  public STATUS_DONE_DEBOUNCE = 2;
  public STATUS_DONE = 1;
  public STATUS_AWAIT = 0;
  public STATUS_CANCEL = -1;
  public STATUS_SHOW_ALL = -2;
  public STATUS_SHOW_PAGE = -3;

  public statuses = {
    '0': '⌛',
    '1': '✔',
    '-1': '❌',
    '2': '🧱'
  };

  public isMobile:boolean = false;

  public users:iUser[] = [];
  // public statuses:any[] = [];
  public usersData:any = {};

  public shows = {};

  public gptsPage: number = 1;

  public gpts:iRequest<iGpts> = {
    data: [],
    last_page: 0
  };

  public currentUser:iUser = null;
  public lastCopied = 0;

  public newUser: iUser = null;

  public reloadTimeout =  50000;
  public timer = null;
  public deletionAllow = false;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }

    this.route.params.subscribe(p => {
      if(!p['user_id']){
        return;
      }
      this.query.one('user', p['user_id']).then(res => {
        this.selectUser(res);
      });
    })

    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = -2;
    }
    this.loadUser();
  }

  dropTimer(){
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
    this.timer = setInterval(() => this.reloadGpts(this.currentUser), this.reloadTimeout > 200 ? this.reloadTimeout : 200);
  }

  removeTimer(){
    if(this.timer){
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  reloadGpts(user:iUser){
    if(this.gptsPage > 1 || !user){
      return;
    }

    const qb = new QueryBuilder()
        .page(this.gptsPage)
        .orderBy('gpt_id', 'desc')
        .per_page(100)
        .where('user_id', '=', user.user_id)
        .with('gptRequest')
        .with('gptResponse')
        .with('gptResponses')
        .with('gptsVariant')
        .with('gptRequests.error')
        .with('calledFunctions.func')
        .where('message', '!=', '')
        .where('parent_gpt_id', '=', 0)
    ;

    this.query.all('gpt', qb).then(res => {
      this.gpts = res;
    });
  }

  ngOnDestroy(){
    this.removeTimer();
  }

  selectUser(user:iUser){
    this.currentUser = user;
    this.newUser = JSON.parse(JSON.stringify(user));
    this.loadGpts(user);
    this.dropTimer();
    if(this.isMobile){
      this.status = -1;
    }
  }

  deleteUser(){
    this.query.del('user', this.currentUser.user_id).then(res => {
      this.loadUser();
      this.currentUser = null;
    });
  }

  loadGpts(user:iUser, page = 1){
    this.gptsPage = page;
    if(this.gptsPage < 1){
      this.gptsPage = 1;
    }
    this.gpts = {data: [], last_page: 0};
    const qb = new QueryBuilder()
        .page(this.gptsPage)
        .orderBy('gpt_id', 'desc')
        .per_page(100)
        .where('user_id', '=', user.user_id)
        .with('gptRequest')
        .with('gptResponse')
        .with('gptResponses')
        .with('gptsVariant')
        .with('gptRequests.error')
        .with('calledFunctions.func')
        .where('message', '!=', '')
        .where('parent_gpt_id', '=', 0)
    ;
    this.query.all('gpt', qb).then(res => this.gpts = res);
  }


  loadUser(status = 1, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder()
        .orderBy('user_id', 'desc')
        .page(this.page)
        .per_page(this.perPage)
        ;
    // if(this.status > -1){
    //   qb.where('status', '=', this.status);
    // }

    this.query.all('user', qb).then(res => {
      this.users = res.data;
      this.usersData = res;
      if(callback){
        callback();
      }
    });
  }

  updateData(){
    this.newUser.paid_per = new Date(this.newUser.paid_per).toISOString();
    this.query.update('user', this.currentUser.user_id, this.newUser).then(res => {
      this.currentUser = res;
      this.currentUser.password = '';
      this.newUser = JSON.parse(JSON.stringify(res));
      this.loadUser(this.status, this.page);
    });
  }

  nextRequest(gpt:iGpts){
    gpt.gpts_request_number++;
    if(gpt.gpts_request_number >= gpt.gpt_requests.length ){
      gpt.gpts_request_number = gpt.gpt_requests.length-1;
    }
  }

  prevRequest(gpt:iGpts){
    gpt.gpts_request_number--;
    if(gpt.gpts_request_number < 0){
      gpt.gpts_request_number = 0;
    }
  }

}
