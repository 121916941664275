import {Component, Input, OnInit} from '@angular/core';
import {AccountService} from "../../query/account";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryService} from "../../query/query";

import {QueryBuilder} from "../../query/query-builder";
import { DeviceDetectorService } from 'ngx-device-detector';
import {iGpts, iGptsVariant, iTranslation} from "../../models/interfaces";

@Component({
  selector: 'app-translation',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit {

  constructor(public account:AccountService, private router:Router, private query:QueryService, private deviceService: DeviceDetectorService, private route: ActivatedRoute) { }

  public status = 1;
  public page = 1;
  public perPage = 10;

  public isMobile:boolean = false;

  public statuses:any[] = [];
  public gptsData:any = {};

  public variants:any = {
    data: []
  };

  public gpts:iTranslation[] = [];
  public currentGpts:iTranslation = null;
  public lastCopied = 0;

  public newGpt: iTranslation = null;

  ngOnInit(): void {
    if(!this.account.isAuth()){
      this.router.navigateByUrl('/login');
    }

    this.route.params.subscribe(p => {
      if(!p['translation_id']){
        return;
      }
      this.query.one('translation', p['translation_id']).then(res => {
        this.selectGpt(res);
      });
    })

    this.isMobile = this.deviceService.isMobile();
    if(this.isMobile){
      this.status = -2;
    }
    else {
      this.loadGptsVariants();
    }

  }

  selectGpt(gpt:iTranslation){
    this.currentGpts = gpt;
    this.newGpt = JSON.parse(JSON.stringify(gpt));
  }

  loadGptsVariants(){
    const qb = new QueryBuilder().per_page(99999).orderBy('key', 'asc');
    this.query.all('translation', qb).then(res => {
      this.gptsData = res;
      this.gpts = res.data;
    });
  }

  updateVariant(variant:iTranslation){
    this.query.update('translation', variant.translation_id, variant).then(res => {
      this.loadGptsVariants();
    });
  }

  createVariant(){
    this.query.create('translation', {}).then(res => {
      this.loadGptsVariants();
    });
  }

  deleteVariant(variant){
    this.query.del('translation', variant.gpts_variant_id).then(res => {
      this.loadGptsVariants();
    });
  }

  loadGpts(status = 1, page = 1, callback = null){
    this.status = status;
    this.page = page;
    if(this.page < 1){
      this.page = 1;
    }

    const qb = new QueryBuilder().per_page(this.perPage).page(this.page).orderBy('key', 'asc');
    this.query.all('translation', qb).then(res => {
      this.gptsData = res;
      this.gpts = res.data;
    });
  }

  updateData(){
    this.query.update('translation', this.currentGpts.translation_id, this.newGpt).then(res => {
      this.currentGpts = res;
    });
  }

}
