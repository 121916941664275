import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { GaugeColor } from '../gauge/gauge.types';

@Component({
  selector: 'app-food-report',
  templateUrl: './food-report.component.html',
  styleUrls: ['./food-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodReportComponent {
	readonly GaugeColor = GaugeColor;

    @Input() foods;
    @Input() day;

    calories = 0;
    proteins = 0;
    fats = 0;
    carbs = 0;

    ngOnInit(){
        for(let food of this.foods){
            this.calories += food.calories;
            this.proteins += food.proteins;
            this.fats += food.fats;
            this.carbs += food.carbs;
        }
    }

    getImage(str:string){
        console.log(str.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu));
        return str.match(/(\p{Emoji_Presentation}|\p{Extended_Pictographic})/gu);
    }
}
