import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { QueryBuilder } from "./query-builder";
import { DataService } from "./data.service";
import { CONFIG } from 'src/store/config';
import {iUser} from "../models/interfaces";
import {environment} from "../environments/environment";


@Injectable()
export class AccountService {

    private _with = [];
    private _withCount = [];
    private promise: Promise<any> | null = null;

    public userData:iUser;

    get user(){
        return this.userData;
    }


    constructor(
        private http: HttpClient,
        private router: Router,
        private data: DataService
    ) { }

    isAuth() { return this.token != null }

    setToken(token:any, save?:any) {
        if (this.data.isClient) {
            if (save)
                localStorage.setItem('api_token', token);
            else
                sessionStorage.setItem('api_token', token);
        }
    }

    dropToken() {
        if (this.data.isClient) {
            localStorage.removeItem('api_token');
            sessionStorage.removeItem('api_token');
        }
    }

    get token() {
        if (this.data.isClient)
            return sessionStorage.getItem('api_token') || localStorage.getItem('api_token');
        return true;
    }

    register(data:any, save:boolean) {
        const self = this;
        const promise = this.http.post(environment.domain + '/api/v1/call/user/register', data).toPromise();

        promise.then(res => {
            if (res && res['api_token']){
                self.promise = null;
                self.dropToken();
                self.setToken(res['api_token'], save);

                this.current();

                if (this.data.isClient && localStorage.getItem('redirect:after-auth')){
                    const red = localStorage.getItem('redirect:after-auth');
                    localStorage.removeItem('redirect:after-auth');
                    this.router.navigateByUrl(red + '');
                }
            }
        });

        return promise;
    }

    login(data:any, save?: boolean) {
        const self = this;
        // const promise = this.http.post(CONFIG.domain + '/api/v1/auth/login', data).toPromise();
        const promise = this.http.post(environment.domain + '/api/v1/call/token/postLogin', data).toPromise();

        promise.then(res => {
            self.promise = null;
            self.dropToken();
            self.setToken(res?.['api_token'], save);

            this.current();
        });
        
        return promise;
    }

    logout() {
        const self = this;

        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        if(self.token){
            headers = headers.append('Authorization', ('Bearer ' + self.token));
        }

        const promise = self.http.get(environment.domain + '/api/v1/auth/logout', {
            headers: headers,
        }).toPromise();

        promise.then(() => {
            self.promise = null;
            self.userData = null;
            self.dropToken();
            location.replace('/');
        });

        return promise;
    }

    with(relation: string) {
        this._with.push('"' + relation + '"');
        return this;
    }

    withCount(relation: string) {
        this._withCount.push('"' + relation + '"');
        return this;
    }

    dropPromise(){
        this.promise = null;
    }

    current(qb?: QueryBuilder): Promise<any> {

        if (this.data.isServer){
            return Promise.reject('server side reject');
        }

        const self = this;

        if(!this.token){
            return Promise.reject('no auth token');
        }



        if (qb) {
            let headers = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            if(self.token){
                headers = headers.append('Authorization', ('Bearer ' + self.token));
            }
            // console.log('headers 1', headers.keys());

            const promise = self.http.get<iUser>(
                environment.domain + '/api/v1/auth/current',
                {
                    headers: headers,
                    params: qb.request
                }
            ).toPromise();

            promise.then(user => {
                self.userData = user;
            },
            err => {
            }).catch(err => {
                console.log(err);
            });

            return promise;
        }

        if (!self.promise) {
            const qb = new QueryBuilder();

            let headers = new HttpHeaders();
            headers = headers.append('Content-Type', 'application/json');
            if(self.token){
                headers = headers.append('Authorization', ('Bearer ' + self.token));
            }

            // console.log('headers 2', headers.keys());
            self.promise = self.http.get(
                environment.domain + '/api/v1/auth/current',
                {
                    headers: headers,
                    params: qb.request
                }
            ).toPromise();

            self.promise.then(
                user => {
                    self.userData = user;
                },
                err => {

                }
            ).catch(err => {
                console.log(err);
            });
        }

        return self.promise;
    }

    changePassword(currentPassword: string, newPassword: string){
        const body = {
            current_password: currentPassword,
            new_password: newPassword
        };

        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        if(this.token){
            headers = headers.append('Authorization', ('Bearer ' + this.token));
        }

        const options = {
            headers: headers
        };
        return this.http.post(environment.domain + '/api/v1/call/customer/changePassword', body, options).toPromise();
    }
}